<template>
  <div class="l-Layout">
    <div class="l-Layout__navigation">
      <Navigation />
    </div>
    <div class="l-Layout__contentWrapper">
      <div class="l-Layout__content">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue';

export default {
  components: {
    Navigation,
  },
};
</script>

<style lang="scss" scoped>
$nav-offset: 140px;

.l-Layout {
  margin: 4rem auto;
  max-width: 80vw;
  width: 100%;

  &__navigation {
    position: fixed;
    width: $nav-offset;
  }
  &__contentWrapper {
    padding-left: $nav-offset;
  }
  &__content {
    padding: 0 16px;
  }
}
</style>
