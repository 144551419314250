import Vue from 'vue';
import VueRouter from 'vue-router';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import SideNavLayout from '@/layouts/SideNavLayout.vue';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: Home,
      },
    ],
  },
  {
    name: 'Playground',
    path: '/playground',
    component: () => import(/* webpackChunkName: "playground" */ '../views/Playground.vue'),
  },
  {
    path: '/',
    name: 'Design',
    component: SideNavLayout,
    children: [
      {
        name: 'Colors',
        path: '/colors',
        component: () => import(/* webpackChunkName: "colors" */ '../views/pages/Colors.vue'),
      },
      {
        name: 'Text',
        path: '/text',
        component: () => import(/* webpackChunkName: "text" */ '../views/pages/Text.vue'),
      },
      {
        name: 'Buttons',
        path: '/buttons',
        component: () => import(/* webpackChunkName: "buttons" */ '../views/pages/Buttons.vue'),
      },
      {
        name: 'Spacing',
        path: '/spacing',
        component: () => import(/* webpackChunkName: "spacing" */ '../views/pages/Spacing.vue'),
      },
      {
        name: 'Cards',
        path: '/cards',
        component: () => import(/* webpackChunkName: "cards" */ '../views/pages/Cards.vue'),
      },
      {
        name: 'Labels',
        path: '/labels',
        component: () => import(/* webpackChunkName: "labels" */ '../views/pages/Labels.vue'),
      },
      {
        name: 'Icons',
        path: '/icons',
        component: () => import(/* webpackChunkName: "icons" */ '../views/pages/Icons.vue'),
      },
      {
        name: 'Inputs',
        path: '/inputs',
        component: () => import(/* webpackChunkName: "inputs" */ '../views/pages/Inputs.vue'),
      },
      {
        name: 'Tables',
        path: '/tables',
        component: () => import(/* webpackChunkName: "tables" */ '../views/pages/Tables.vue'),
      },
      {
        name: 'Chips',
        path: '/chips',
        component: () => import(/* webpackChunkName: "chips" */ '../views/pages/Chips.vue'),
      },
      {
        name: 'Grid',
        path: '/grid',
        component: () => import(/* webpackChunkName: "grid" */ '../views/pages/Grid.vue'),
      },
      {
        name: 'Modals',
        path: '/modals',
        component: () => import(/* webpackChunkName: "modals" */ '../views/pages/Modals.vue'),
      },
      {
        name: 'Toasts',
        path: '/toasts',
        component: () => import(/* webpackChunkName: "toasts" */ '../views/pages/Toasts.vue'),
      },
      {
        name: 'Tooltips',
        path: '/tooltips',
        component: () => import(/* webpackChunkName: "tooltips" */ '../views/pages/Tooltips.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

export default router;
