<template>
  <div
    :class="[
      'c-Nav',
      navAnimationState
    ]"
  >
    <div class="c-Nav__inner">
      <div class="c-Nav__linkWrapper">
        <router-link
          to="/colors"
          class="c-Nav__link"
        >COLORS</router-link>
      </div>
      <div class="c-Nav__linkWrapper">
        <router-link
          to="/text"
          class="c-Nav__link"
        >TEXT</router-link>
      </div>
      <div class="c-Nav__linkWrapper">
        <router-link
          to="/spacing"
          class="c-Nav__link"
        >SPACING</router-link>
      </div>
      <div class="c-Nav__linkWrapper">
        <router-link
          to="/grid"
          class="c-Nav__link"
        >GRID</router-link>
      </div>
      <div class="c-Nav__linkWrapper">
        <router-link
          to="/cards"
          class="c-Nav__link"
        >CARDS</router-link>
      </div>
      <div class="c-Nav__linkWrapper">
        <router-link
          to="/labels"
          class="c-Nav__link"
        >LABELS</router-link>
      </div>
      <div class="c-Nav__linkWrapper">
        <router-link
          to="/icons"
          class="c-Nav__link"
        >ICONS</router-link>
      </div>
      <div class="c-Nav__linkWrapper">
        <router-link
          to="/buttons"
          class="c-Nav__link"
        >BUTTONS</router-link>
      </div>
      <div class="c-Nav__linkWrapper">
        <router-link
          to="/inputs"
          class="c-Nav__link"
        >INPUTS</router-link>
      </div>
      <div class="c-Nav__linkWrapper">
        <router-link
          to="/tables"
          class="c-Nav__link"
        >TABLES</router-link>
      </div>
      <div class="c-Nav__linkWrapper">
        <router-link
          to="/chips"
          class="c-Nav__link"
        >CHIPS</router-link>
      </div>
      <div class="c-Nav__linkWrapper">
        <router-link
          to="/modals"
          class="c-Nav__link"
        >MODALS</router-link>
      </div>
      <div class="c-Nav__linkWrapper">
        <router-link
          to="/toasts"
          class="c-Nav__link"
        >TOASTS</router-link>
      </div>
      <div class="c-Nav__linkWrapper">
        <router-link
          to="/tooltips"
          class="c-Nav__link"
        >TOOLTIPS</router-link>
      </div>
      <div class="c-Nav__linkWrapper">
        <router-link
          to="/playground"
          class="c-Nav__link"
        >PLAYGROUND🧪</router-link>
      </div>
      <br>
      <div class="c-Nav__linkWrapper">
        <router-link
          to="/"
          class="c-Nav__link"
        >Home</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  data: () => ({
    navAnimationState: 'hidden',
  }),
  mounted() {
    setTimeout(() => {
      this.navAnimationState = 'visible';
    });
  },
};
</script>

<style lang="scss">

$linkCount: 16;

.c-Nav {
  $parent: &;

  &.visible {
    #{$parent}__linkWrapper {
      transition: color $transition-speed;
      @for $i from 1 through $linkCount {
        &:nth-of-type(#{$i}) {
          opacity: 1;
          transform: translateX(0px);
          transition: transform 1s, opacity 1s;
          transition-delay: calc(80ms * #{$i});
        }
      }
    }
  }

  &:hover {
    #{$parent}__link {
      color: rgba(0, 0, 0, 1);
      transition: color $transition-speed;
      &.router-link-exact-active {
        color: $un-blue;
      }
    }
  }

  &__inner {
    display: inline-flex;
    flex-direction: column;
  }

  &__linkWrapper {
    display: flex;
    transform: translateX(-30px);
    opacity: 0;
  }

  &__link {
    display: inline;
    padding: 4px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 600;
    color: rgba(0, 0, 0, .2);
    transition: color $transition-speed;

    &.router-link-exact-active,
    &:hover,
    &:focus  {
      color: $un-blue!important;
      text-decoration: underline;
      transition: color $transition-speed;
    }
  }
}

</style>
