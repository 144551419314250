<template>
  <footer>
    <div class="logo">
      <img src="/img/wave.png" alt="">
    </div>
    {{ currentYear }} © Unbabel Inc.
    <div class="rightside">
      <a href="https://unbabel.com/about/">About Us</a>
      <a href="https://unbabel.com/research/">Research</a>
      <a href="https://unbabel.com/careers/">careers</a>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss">
footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: white;
  width: 100vw;
  padding: 1em 2em;

  .logo {
    margin-right: 1em;
    img {
      height: 40px;
    }
  }

  .rightside {
    margin-left: auto;
    display: flex;
    > * {
      margin-left: 20px;
      color: #1111A6;
    }
  }
}

</style>
