import Vue from 'vue';
import VuePrlx from 'vue-prlx';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';

import './css/flexboxgrid.min.css';

Vue.use(VuePrlx);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
