<template>
  <div>
    <Topnav />
    <div
      ref="lockedSection"
      class="l-Fullpage m-0 w-100 locked"
    >
      <div class="l-Half is-left">
        <div class="video-container">
          <video
            ref="videoPlayer"
            class="u-Video"
            src="/videos/video.mp4"
            autoplay
            loop
            muted
          />
        </div>
      </div>
      <div class="l-Half is-right">
        <div class="u-SquareContainer">
          <div class="u-Square">
            <h1 class="c-title small">DESIGN<br>@Unbabel</h1>
            <br>
            <h2 style="color: #444B55;">Creating understanding</h2>
          </div>
        </div>
      </div>
    </div>

    <div
      ref="lockCompensation"
      class="l-Fullpage transparent"
    ></div>

    <div class="l-Fullpage w-100">
      <div class="l-Fullpage">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <p
              class="paragraph"
              v-prlx="{ fromBottom: true }"
            >
              Efficient to use, flexible across applications,
              and able to feature localized content in a globally consistent way.
              These guidelines cover 5 elements: branding,
              illustrations, design system, component library, and accessibility.
            </p>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="section-wrapper">
              <div class="section">
                Brand & Identity
              </div>
              <div class="section">
                Illustrations
              </div>
              <div class="section">
                Design System
              </div>
              <div class="section">
                Component Library
              </div>
              <div class="section">
                Accessibility
              </div>
              <img
                src="https://unbabel.com/shapes/wave-2-pink-orange.png"
                alt=""
                v-prlx="{ fromBottom: true }"
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 pb-10 white-bg"></div>

    <div class="l-Fullpage w-100 pb-10">
      <div class="l-Fullpage">
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <div class="absolute index">
              <h1 class="c-title">The Unbabel<br>Way</h1>
              <br>
              <br>
              <br>
              <a class="button arrow">Read More</a>
            </div>
            <img
              src="https://unbabel.com/shapes/triangle-blue-pink.png"
              alt=""
              class="absolute"
              v-prlx="{ fromBottom: true }"
            >
          </div>
          <div class="col-sm-12 col-md-8">
            <div class="u-Image__wrapper" >
              <div class="u-Image" v-prlx/>
            </div>
            <img
                src="https://unbabel.com/shapes/circle-pink-yellow.png"
                alt=""
              >
          </div>
        </div>
      </div>
    </div>

    <div class="l-Fullpage w-100 pb-10 compact">
      <div class="l-Fullpage">
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <div class="o-Nav">
              <router-link
                to="/colors"
                class="o-Nav__link"
              >COLORS</router-link>
              <router-link
                to="/text"
                class="o-Nav__link wip"
              >TEXT</router-link>
              <router-link
                to="/spacing"
                class="o-Nav__link wip"
              >SPACING</router-link>
              <router-link
                to="/grid"
                class="o-Nav__link"
              >GRID</router-link>
              <router-link
                to="/cards"
                class="o-Nav__link wip"
              >CARDS</router-link>
              <router-link
                to="/labels"
                class="o-Nav__link wip"
              >LABELS</router-link>
              <router-link
                to="/toasts"
                class="o-Nav__link wip"
              >TOASTS</router-link>
            </div>
          </div>
          <div class="col-xs-12 col-md-6">
            <div class="o-Nav">

              <router-link
                to="/icons"
                class="o-Nav__link wip"
              >ICONS</router-link>
              <router-link
                to="/buttons"
                class="o-Nav__link"
              >BUTTONS</router-link>
              <router-link
                to="/inputs"
                class="o-Nav__link wip"
              >INPUTS</router-link>
              <router-link
                to="/tables"
                class="o-Nav__link wip"
              >TABLES</router-link>
              <router-link
                to="/chips"
                class="o-Nav__link wip"
              >CHIPS</router-link>
              <router-link
                to="/modals"
                class="o-Nav__link wip"
              >MODALS</router-link>
              <router-link
                to="/tooltips"
                class="o-Nav__link wip"
              >TOOLTIPS</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
/* eslint-disable */
// import videojs from 'video.js';
import Topnav from '@/components/Topnav';
import Footer from '@/components/Footer';

export default {
  name: 'Home',
  components: {
    Topnav,
    Footer,
  },
  data() {
    return {
      player: null,
      options: {
        autoplay: true,
        controls: false,
        height: window.innerHeight,
        sources: [
          {
            src: '/videos/video.mp4',
            type: 'video/mp4',
          },
        ],
      },
    };
  },
  mounted() {
    const unlockOffset = this.$refs.lockedSection.getBoundingClientRect().top
      + document.documentElement.scrollTop;

    let opacity = 1;

    document.addEventListener('scroll', () => {
      const currentTop = document.documentElement.scrollTop;
      const lockedSectionHeight = this.$refs.lockedSection.getBoundingClientRect().height;
      const lockedSectionBotton = this.$refs.lockedSection.getBoundingClientRect().top + lockedSectionHeight;

      if (currentTop <= lockedSectionBotton) {
        opacity = 1 - currentTop / lockedSectionBotton * 1.5;
      } else {
        opacity = 0;
      }
      this.$refs.lockedSection.style.opacity = opacity;
    });
  },
  beforeDestroy() {
    document.removeEventListener('scroll');
  },
};
</script>

<style lang="scss" scoped>
.l-Fullpage {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: 75vw;
  width: 100%;
  min-height: 100vh;
  background: white;

  &.transparent {
    background: transparent;
  }

  &.compact {
    min-height: 80vh;
    max-width: 70vw;
  }

  &.locked {
    position: fixed;
    top: 0;
    left: 0;
  }

  .row {
    width: 100%;
  }
}

.l-Half {
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 50vw;
  &.is-left {
    width: 70vw;
  }
}

.u-SquareContainer {
  position: relative;
  overflow: visible;
}

.u-Square {
  position: absolute;
  top: -250px;
  left: -350px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #FFF;
  padding: 3rem;
  height: 500px;
  width: 500px;
}

.c-title {
  font-size: 9rem;
  font-weight: 600;
  margin: 0;
  z-index: 1;


  &.small {
    color: #444B55;
    position: relative;
    font-size: 7rem;
    padding-left: 2em;
  }
}

.u-Image {
  @include imageCard;
  height: 450px;
  background-image: url("~@/assets/images/logo_aereal.jpg");

  &__wrapper {
    transform: translateY(-50px) scale(1.3, 1.3);
  }
}
.video-container {
  border-top-right-radius: $cardPadding;
  border-bottom-right-radius: $cardPadding;
  overflow: hidden;
}
.u-Video {
  height: 100vh;
  width: auto;
  margin-left: -20vw;
  transform: scaleX(-1);
}

.o-Nav {
  display: inline-flex;
  flex-direction: column;

  &__link {
    font-family: $montserrat;
    font-size: 4rem;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 900;
    color: #000;
    transition: color $transition-speed;

    &.wip {
      text-decoration: line-through;
      color: $un-n200;
    }

    &:hover, &:focus {
      color: $un-blue;
      transition: color $transition-speed;
    }
  }
}

.paragraph {
  font-size: 1.5em;
}

.pb-10 {
  padding-bottom: 10em;
}

.m-0 {
  margin: 0;
}
.w-100 {
  width: 100%;
  max-width: 100%!important;
}
.hidden {
  display: none!important;
}
.absolute {
  top: 0;
  position: absolute;
}
.index {
  z-index: 10;
}

.white-bg {
  position: relative;
  background: white;
}

a.button {
  margin: 20px;
  padding: .5em 1em;
  font-size: 20px;
  font-weight: bold;
}

.section-wrapper {
  padding: 0 4em;
  display: inline-flex;
  flex-direction: column;

  .section {
    display: inline-block;
    border: 1px solid #DDD;
    padding: 1em 4em 1em 1em;
    margin-bottom: .5em;
    position: relative;

    &::after {
      position: absolute;
      right: 15px;
      display: inline-block;
      padding-left: 8px;
      content: "\0279E"; // arrow right unicode
      -webkit-transition: transform 0.3s ease-out;
      -moz-transition: transform 0.3s ease-out;
      -ms-transition: transform 0.3s ease-out;
      -o-transition: transform 0.3s ease-out;
      transition: transform 0.3s ease-out;
    }

    &:hover {
      color: #1111A6;
      background-color: #E9EAFB;
      cursor: pointer;
      transition: all 0.3s cubic-bezier(.25,.8,.25,1);

      &::after {
        -webkit-transform: translateX(4px);
        -moz-transform: translateX(4px);
        -ms-transform: translateX(4px);
        -o-transform: translateX(4px);
        transform: translateX(4px);
      }
    }
  }
}

.arrow {
  color: #3843D0;
  background-color: #F5F7FA;
  margin: 1em 0;
  transition: color .5s;
  box-shadow: 1;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);

  &::after {
    display: inline-block;
    padding-left: 8px;
    content: "\0279E"; // arrow right unicode
    -webkit-transition: transform 0.3s ease-out;
    -moz-transition: transform 0.3s ease-out;
    -ms-transition: transform 0.3s ease-out;
    -o-transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
  }

  &:hover {
    color: #1111A6;
    background-color: #F5F7FA;
    cursor: pointer;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);

    &::after {
      -webkit-transform: translateX(4px);
      -moz-transform: translateX(4px);
      -ms-transform: translateX(4px);
      -o-transform: translateX(4px);
      transform: translateX(4px);
    }
  }
}
</style>
