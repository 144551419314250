<template>
  <div class="c-Nav__wrapper">
    <nav
      ref="navigation"
      class="c-Nav white"
    >
      <div class="logo">
        <img src="/img/wave.png" alt="">
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Topnav',
  data: () => ({
    navAnimationState: 'hidden',
  }),
  mounted() {
    document.addEventListener('scroll', () => {
      const currentTop = document.documentElement.scrollTop;
      if (currentTop * 1.8 >= window.innerHeight - 50) {
        this.$refs.navigation.classList.remove('white');
      } else {
        this.$refs.navigation.classList.add('white');
      }
    });
  },
  beforeDestroy() {
    document.removeEventListener('scroll');
  },
};
</script>

<style lang="scss">
.c-Nav__wrapper {
  position: fixed;
  width: 100%;
  height: 6em;
  padding: .5em 1em;
  background: transparent;
  z-index: 100;
}
.c-Nav {
  &.white {
    .logo img {
      filter: brightness(0) invert(1);
      transition: filter .5s;
    }
  }

  .logo img {
    width: 80px;
    height: auto;
    filter: brightness(1) invert(0);
      transition: filter .5s;
  }
}
</style>
